import React, { useEffect } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import GreenHeading from "../components/heading"
import Layout from "../components/layout"
import SecondaryHeader from "../components/header/header-secondary"
import device from "../components/device"

const Wrapper = styled.section`
  .secondary-header-wrapper {
    background: var(--darkgrey);
    color: var(--white);
  }
  .content {
    max-width: 1500px;
    width: 70%;
    margin: 5rem auto 10rem auto;
    ${device.small`width: var(--spread);`}
    .heading-wrapper {
      .heading {
        text-align: center;
        line-height: 1.2;
        font-size: 3rem;
        ${device.small`display: block; line-height: 1; font-size: 2.5rem;`}
        .secondary-heading {
          margin-left: 3rem;
          ${device.small`margin-left: 2rem; font-size: 2rem;`}
        }
      }
    }
  }

  .success-message {
    min-height: 30vh;
    margin-top: 4rem;

    p {
      margin: 1rem 0;
    }
  }
`

function FormSubmitted({ location, data }) {
  useEffect(() => {
    !location.state && navigate("/contact")
  }, [location.state])

  if (!location.state) return null
  return (
    <Layout>
      <Helmet>
        <title>{`${data.site.siteMetadata.title} | ${data.wpgraphql.page.seo.title}`}</title>
        <meta
          name="description"
          content={data.wpgraphql.page.seo.opengraphDescription}
        />
        <meta
          property="og:url"
          content={data.wpgraphql.page.seo.opengraphUrl}
        />
        <meta
          property="og:site_name"
          content={data.wpgraphql.page.seo.opengraphSiteName}
        />
        <meta property="og:title" content={data.wpgraphql.page.seo.title} />
        <meta
          property="og:description"
          content={data.wpgraphql.page.seo.opengraphDescription}
        />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Twitter */}
        <meta
          name="twitter:title"
          content={data.wpgraphql.page.seo.twitterTitle}
        />
        <meta
          name="twitter:description"
          content={data.wpgraphql.page.seo.twitterDescription}
        />

        <meta name="geo.region" content="US-PA" />
        <meta name="geo.placename" content="King of Prussia" />
        <meta name="geo.position" content="40.091710;-75.346160" />
        <meta name="ICBM" content="40.091710, -75.346160" />
      </Helmet>
      <Wrapper>
        <div className="secondary-header-wrapper">
          <SecondaryHeader />
        </div>
        <div className="content">
          <div className="heading-wrapper">
            <h1 className="heading">
              <GreenHeading size="6rem" text="Thank you!" />
            </h1>
          </div>
          <section
            className="success-message"
            dangerouslySetInnerHTML={{
              __html: data.wordpressPage.acf.success_message,
            }}
          />
        </div>
      </Wrapper>
    </Layout>
  )
}

export default FormSubmitted

export const query = graphql`
  {
    wordpressPage(title: { eq: "Contact" }) {
      acf {
        success_message
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
    wpgraphql {
      page(id: "231", idType: DATABASE_ID) {
        nexvelschemapagesposts {
          videos {
            title
            url
            description
            thumbnailImage {
              sourceUrl
              imageFile {
                childImageSharp {
                  fixed {
                    src
                  }
                }
              }
            }
            uploadDate
          }
          questionsAndAnswers {
            question
            answer
          }
          maps {
            mapUrl
          }
          digitaldocuments {
            title
          }
          images {
            image {
              date
              description
              title
              sourceUrl
              imageFile {
                childImageSharp {
                  fixed {
                    src
                  }
                }
              }
            }
          }
          areaServedLandingPageSchema
          serviceSchema {
            serviceNameSchema
            linkToRelevantServicePageSchema
          }
          keywordsSchema {
            keywordSchema
          }
          localAwardsSchema {
            localAwardSchema
          }
          latitudeSchema
          longitudeSchema
          relevantExternalPagesSchema {
            relevantExternalPageSchema
          }
        }
        seo {
          title
          opengraphDescription
          opengraphUrl
          opengraphSiteName
          twitterTitle
          twitterDescription
        }
      }
    }
    wordpressAcfOptions {
      options {
        cities {
          city
        }
        services {
          service
          link_to_relevant_page_on_your_website
        }
      }
    }
  }
`
